.rates-page {
  display: flex;
  flex-direction: column;
  .rate {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background: var(--light_blue_40);
    padding: 0;
    height: 110px;
    margin-bottom: 20px;
    border-radius: 0px 10px 10px 0px;
    transition: 500ms;

    &:hover {
      filter: hue-rotate(60deg);
    }

    .title {
      height: 100%;
      width: 30px;
      margin: 0;
      background: var(--color-primary);
      color: var(--white);
      padding: 10px;
      border-radius: 10px 0px 0px 10px;
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      p {
        margin: 0;
        font-size: 14px;
        transform: rotate(-90deg);
      }
    }

    .description {
      width: 70%;
      margin-right: 10px;
    }

    .classifiers-amount {
      display: flex;
      flex-direction: column;
      .text {
        white-space: nowrap;
        font-weight: 600;
        font-size: 10px;
      }

      .value {
        white-space: nowrap;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-left: 10px;
      margin-right: 10px;
      width: 80px;

      .price-itself {
        font-size: 25px;
      }

      .metric {
        font-size: 10px;
      }
    }
  }
}