.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .logo-container {
    display: flex;
  }

  @media (max-width: 1024px) {
    .logo-container {
      display: none;
    }
  }

  .centered-points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
      width: auto;
      opacity: 0.8; color: var(--gray_200);
      display: flex;
      flex-direction: row;
      justify-content: center;
      text-align: center;
    }

    .points-themselves {
      margin-left: auto;
      margin-right: auto;
      max-width: 70vw;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .point {
        max-width: 30vw;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;


        i {
          opacity: 0.8;
          transition: all 0.3s ease-in-out;
          filter: drop-shadow(0 0 10px rgba(91, 132, 161, 0.2))
                 drop-shadow(0 0 20px rgba(91, 132, 161, 0.1));
          
          &:hover {
            transform: scale(1.1);
            opacity: 1;
            filter: drop-shadow(0 0 15px rgba(91, 132, 161, 0.3))
                   drop-shadow(0 0 30px rgba(91, 132, 161, 0.2));
          }
          color: #5b84a1;
          background: linear-gradient(45deg, rgba(20, 187, 212, 0.8) 0%, rgba(89, 129, 248, 0.8) 50%, rgba(255,255,255,0.8) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

        }

        
        h3 {
          opacity: 1;
          width: fit-content;
          font-family: Montserrat;
          text-shadow: 0 0 10px rgba(255, 255, 255, 0.1),
                      0 0 20px rgba(255, 255, 255, 0.1),
                      0 0 30px rgba(91, 132, 161, 0.1);
          background: linear-gradient(45deg, #1b5d8f 0%, #eff5f7 50%, #ffffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 600;
          letter-spacing: -0.02em;
          text-align: center;
          margin: 0px;
          padding: 0px;
          width: fit-content;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 510px) {
    .centered-points {
      padding: 0;
      margin: 0;
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 16px;
        margin: 0;
        margin-top: 10px;
      }

      .points-themselves {
        margin: 0;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .point {
          margin: 0;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;  
          min-width: unset;
          width: 30%;

          i {
            display: flex;
            font-size: 40px;
            margin-right: auto;
            margin-left: auto;
          }

          h3 {
            margin: 0;
            font-size: 10px;
            text-align: center;
            line-height: 1.01em;
            width: 100%;
          }
        }
      }
    }
  }
}