footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    padding: 0;
    font-size: var(--font-size-1);
  }

  * {
    margin: 10px;
  }
}

@media (max-width: 510px) {
  footer {
    flex-wrap: wrap;
  }
}