@media (max-width: 700px) {
  .labeler {
    position: absolute;
    left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
    max-height: unset;
    height: unset;
    height: 70vh;

    .left-column-labeler {
      width: 100%;
      position: fixed;
      z-index: 1;
      overflow: scroll;
      height: 40vh;

      img {
        height: 100vh;
      }
    }

    .right-column {
      width: 98vw;
      margin-top: 42vh;
      height: 50vh;
      overflow: scroll;
      max-width: unset;

    }
  }
}
