.widget-video-list {
  border-radius: 8px;
  //position: absolute;
  //top: 10px;
  //right: 6px;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  background: var(--white);
  width: 100%;
  min-height: 70px;
  z-index: 2;
  max-height: 20vh;
  overflow-y: scroll;

  h5 {
    margin: 0;
  }

  .file-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-size: 8px;
    border: 2px solid var(--light_blue_40);
    margin-bottom: 8px;
    box-sizing: border-box;
    border-radius: 3px;

    &:hover {
      background: var(--light_blue_40);
    }
  }
}