ul.breadcrumbs {
  color: var(--gray_500);
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: var(--gray_500);
  }
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    cursor: pointer;
    margin-right: 10px;
    height: 40px;
  }

  li.active {
    color: var(--red);
  }
}