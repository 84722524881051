header.for-user {
  width: 100%;
  position: relative;
  z-index: 1;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: var(--blue-primary);
  margin: 0;
  background: linear-gradient(135deg, rgba(91,132,161,0.05) 0%, rgba(255,255,255,0.1) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(91,132,161,0.1);
  transition: all 0.3s ease-in-out;
  height: 40px;

  h1 {
    margin-left: 0;
    display: none;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }

  p {
    padding: 0;
  }

  * {
    margin: 10px;
  }

  .my-id {
    font-size: 10px;
    display: none;
  }

  @media(max-width: 600px) {
    .my-id {
      font-size: 10px;
      display: flex;
    }
  }
}