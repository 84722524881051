.project-control {
  padding-left: 0px;
  .breadcrumb-project-name {
    h3 {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 10px;
    }
  }
}