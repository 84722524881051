.cabinet {
  display: flex;
  flex-direction: row;

  .cabinet-content {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    padding: 0px;
    padding-left: 0px;  
    padding-right: 0px;
    @media (max-width: 1000px) {
      padding: 0px;
      width: 100%;
    }
    box-sizing: border-box;
    //background-color: var(--gray-40);
  }
}

