.left-menu {
  color: var(--blue-primary);
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: 14px;
  height: fit-content;
  height: 100vh;
  background: var(--gray-15);
  background: linear-gradient(135deg, #5b84a10d 0%, rgba(255,255,255,0.1) 100%);  
  width: 14%;
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);

  .cabinet-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    padding-left: 0;
    margin-left: 0px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--white);

    i {
      font-size: 30px;
      margin-left: 0px;
      margin-right: 10px;
      text-align: center;
    }
  }

  .circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--blue-primary);
    color: var(--white);

  }
}

@import "left_menu_md";
@import "left_menu_sm";
@import "left_menu_xs";