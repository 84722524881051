:root {
  --red: #FD2135;
  --blue: #1F2C94;
  --color-primary: rgba(17, 71, 131, 1);
  --azure_a100: #2787f5;
  --black: #303030;
  --black_alpha4: rgba(0, 0, 0, 0.04);
  --black_alpha8: rgba(0, 0, 0, 0.08);
  --black_alpha12: rgba(0, 0, 0, 0.12);
  --black_alpha16: rgba(0, 0, 0, 0.16);
  --black_alpha24: rgba(0, 0, 0, 0.24);
  --black_alpha36: rgba(0, 0, 0, 0.36);
  --black_alpha40: rgba(0, 0, 0, 0.40);
  --black_alpha48: rgba(0, 0, 0, 0.48);
  --black_alpha56: rgba(0, 0, 0, 0.56);
  --black_alpha72: rgba(0, 0, 0, 0.72);
  --black_alpha88: rgba(0, 0, 0, 0.88);
  --black_blue10: #000c1a;
  --black_blue24: #001c3d;
  --black_blue24_alpha8: rgba(0, 28, 61, 0.08);
  --black_blue24_alpha24: rgba(0, 28, 61, 0.24);
  --black_blue30_alpha66: rgba(0, 36, 77, 0.66);
  --black_blue45_alpha10: rgba(0, 57, 115, 0.10);
  --blue_400: #5181b8;
  --blue_400_alpha20: rgba(81, 129, 184, 0.20);
  --blue_400_alpha48: rgba(81, 129, 184, 0.49);
  --blue_420: #4a76a8;
  --blue_430: rgba(31, 99, 163, 1);
  --blue_430-text: rgba(191, 209, 255, 1);
  --blue_600: #2a5885;
  --blue_640: #224b7a;
  --blue_800: #1d3a5c;
  --blue_980_alpha12: rgba(0, 20, 51, 0.12);
  --blue_bright: #5c9ce6;
  --brown_gold: #9e8f72;
  --brown_light: #c2b79f;
  --gold_200: #f4e7c3;
  --gold_250: #e3d3ac;
  --gold_300: #d1c097;
  --gold_400: #ab9871;
  --gold_500: #857250;
  --gray-15: rgba(250, 250, 250, 1);
  --gray-20: rgba(245, 245, 245, 1);
  --gray-40: #f5f5f5;
  --gray_70: #555555;
  --gray_100: #e1e3e6;
  --gray_100_alpha60: rgba(225, 227, 230, 0.60);
  --gray_200: rgba(191, 191, 191, 1);
  --gray_300: #b2b2b2;
  --gray_400: #939393;
  --gray_500: #828282;
  --gray_600: #656565;
  --gray_700: #555555;
  --gray_700_alpha60: rgba(85, 85, 85, 0.60);
  --gray_750: #424242;
  --gray_800: #333333;
  --gray_800_alpha72: rgba(51, 51, 51, 0.72);
  --gray_800_alpha88: rgba(51, 51, 51, 0.88);
  --gray_850: #292929;
  --gray_900: #222222;
  --gray_900_alpha16: rgba(34, 34, 34, 0.16);
  --gray_900_alpha72: rgba(34, 34, 34, 0.72);
  --gray_900_alpha88: rgba(34, 34, 34, 0.88);
  --gray_960: #0a0a0a;
  --green_alpha16: rgba(75, 179, 75, 0.16);
  --green_350: #4bb34b;
  --green_400: #43a843;
  --green_600: #1b7a1b;
  --green_light: #def0d3;
  --green_nice: #3dcc4b;
  --light_blue_40: #e5ebf1;
  --light_blue_40_alpha72: rgba(229, 235, 241, 0.72);
  --light_blue_80: #dfe6ed;
  --light_blue_100: #dae2ea;
  --light_blue_300: #9eb4c8;
  --light_blue_400: #7996b2;
  --light_blue_500: #577ca1;
  --light_blue_700: #45688e;
  --light_blue_a300: #8fadc8;
  --blue-primary: rgba(17, 71, 131, 1);
  --orange: #ffa000;
  --orange_fire: #f05c44;
  --orange_prominent: #ff724c;
  --purple: #735ce6;
  --purple_light: #937ff5;
  --red_alpha16: rgba(230, 70, 70, 0.16);
  --red_alpha12: rgba(230, 70, 70, 0.12);
  --red_dark: #db3b3b;
  --red_light: #ffd6cc;
  --red_nice: #ff3347;
  --sky_60: #cce9ff;
  --sky_80: #b2deff;
  --sky_100: #99d3ff;
  --sky_200: #70c1ff;
  --steel_gray_10: #fafbfc;
  --steel_gray_20: #f7f8fa;
  --steel_gray_40: #f0f2f5;
  --steel_gray_60: #edeef0;
  --steel_gray_80: #e7e8ec;
  --steel_gray_100: #dce1e6;
  --steel_gray_120: #d3d9de;
  --steel_gray_140: #c5d0db;
  --steel_gray_200: #aeb7c2;
  --steel_gray_200_alpha12: rgba(174, 183, 194, 0.12);
  --steel_gray_200_alpha16: rgba(174, 183, 194, 0.16);
  --steel_gray_300: #99a2ad;
  --steel_gray_400: #818c99;
  --steel_gray_500: #6f7985;
  --steel_gray_540: #626d7a;
  --steel_gray_600: #4e5966;
  --steel_gray_a540: #55677d;
  --steel_gray_a540_alpha64: rgba(85, 103, 125, 0.64);
  --transparent_black: rgba(0, 0, 0, 0.00);
  --transparent_white: rgba(255, 255, 255, 0.00);
  --turquoise: #63b9ba;
  --violet: #792ec0;
  --violet_dark: #1f1b2c;
  --violet_light: #a393f5;
  --violet_muted: #4b4d61;
  --white: #ffffff;
  --white_alpha72: rgba(255, 255, 255, 0.72);
  --white_alpha64: rgba(255, 255, 255, 0.64);
  --white_alpha48: rgba(255, 255, 255, 0.48);
  --white_alpha40: rgba(255, 255, 255, 0.40);
  --white_alpha24: rgba(255, 255, 255, 0.24);
  --white_alpha16: rgba(255, 255, 255, 0.16);
  --white_alpha12: rgba(255, 255, 255, 0.12);
  --white_alpha8: rgba(255, 255, 255, 0.08);
  --white_alpha4: rgba(255, 255, 255, 0.04);
  --white_blue20: #cce4ff;
  --white_blue32: #add3ff;
  --yellow: #ffc107;
  --yellow_light: #faefd2;
  --lavender_100: #e6e9ff;
  --lavender_200: #ccd3ff;
  --lavender_300: #adb8ff;
  --lavender_700: #5965b3;
  --lavender_800: #404980;
  --lavender_900: #262b4d;
}