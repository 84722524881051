.data-source-widget {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  background: var(--white);
  color: var(--black);
  padding: 14px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  p.title {
    //styleName: Title/Medium;
    font-family: Montserrat;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--blue-primary);
  }

  p.description {
    font-size: 10px;
    color: var(--gray_900);
  }

  .files-amount {
    font-size: 10px;
    margin: 0;
    margin-right: 3px;
  }
}