.controls {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px;
  border-radius: 10px;
  z-index: 2;

  i {
    cursor: pointer;
    opacity: 0.6;
    max-width: 40px;
    transition: 400ms;
  }

  i:hover {
    opacity: 0.8;
  }

}

.control-line {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  backdrop-filter: blur(5px);
  background: rgba(255,255,255,0.3);
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  .progress {
    display: flex;
    height: 100%;
    background: var(--light_blue_700);
    transition: 20ms;
    opacity: 0.9;
  }

  .point {
    border-radius: 10px;
    display: flex;
    height: 100%;
    position: absolute;
    top: 0;
    width: 4px;
    transition: 100ms;
    background: var(--color-primary);
    z-index: 1;

    .helper {
      background: var(--color-primary);
      color: var(--white);
      position: absolute;
      padding: 0;
      left: 0;
      border-radius: 3px;
      padding-top: 4px;
      transition: 190ms;
      opacity: 0;
      pointer-events: none;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      height: auto;
      padding: 4px;

      .caramel {
        position: relative;
        z-index: 10;
        display: flex;
        width: fit-content;
        font-size: 16px;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 7px;
        background: var(--light_blue_40);
        color: var(--color-primary);
        margin: 2px;
      }
    }
    &:hover {
      .helper {
        opacity: 1;
      }
    }
  }
}

video {
  border-radius: 5px;
  box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);
}