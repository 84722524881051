.sign-in {

  h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    opacity: 0.8;
    margin-bottom: 20px;
  }

  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 10px;
  background: linear-gradient(135deg, rgba(91,132,161,0.05) 0%, rgba(255,255,255,0.1) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding-bottom: 50px;
}