.user-registration {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .user-registration-content {
    display: flex;
    flex-direction: column;
    background: var(--color-primary);
    padding: 10px;
    border-radius: 10px;
    min-width: 320px;
    form {
      display: flex;
      flex-direction: column;
      input {
        margin-bottom: 10px;
      }
      p {
        margin-top: 10px;
        width: 100%;
        text-align: center;
        opacity: 0.7;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

}