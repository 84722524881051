.label-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: var(--white);
  padding: 10px;
  border-radius: 9px;
  border: 1px solid var(--light_blue_400);
  margin-bottom: 5px;
  margin-right: 5px;
  color: var(--color-primary);
  min-width: 200px;

  .title {
    font-size: 10px;
  }
  .amount {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    padding: 0;
  }
}