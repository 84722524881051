header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  width: fit-content;

  @media (max-width: 1024px) {
  }


  h1 {
    color: var(--white);
    font-size: 22px;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: Montserrat;
    letter-spacing: -0.1em;
    opacity: 1;
    cursor: pointer;
    background: linear-gradient(90deg, #5b84a1 0%, #ffffff 50%, #5b84a1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2),
                 0 0 20px rgba(255, 255, 255, 0.2), 
                 0 0 30px rgba(91, 132, 161, 0.2),
                 0 0 40px rgba(91, 132, 161, 0.2),
                 0 0 70px rgba(91, 132, 161, 0.2),
                 0 0 80px rgba(91, 132, 161, 0.2),
                 0 0 100px rgba(91, 132, 161, 0.2),
                 0 0 150px rgba(91, 132, 161, 0.1);
  }

  p, a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: var(--white);
    font-size: 16px;
    font-weight: 400;
    transition: all 0.2s ease-in-out;
    position: relative;
    background: linear-gradient(90deg, #5b84a1 0%, #6296af 50%, #5b84a1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.01),
                 0 0 20px rgba(255, 255, 255, 0.01),
                 0 0 30px rgba(91, 132, 161, 0.01),
                 0 0 40px rgba(91, 132, 161, 0.01);
    opacity: 0.9;
    margin-right: 20px;
    font-family: Montserrat;
  }


  * {
    margin: 10px;
  }
}