.btn--open-modal {
  position: fixed;
  z-index: 4;
  bottom: 11%;
  right: 10%;
  padding: 5px;
  //padding-bottom: 0px;
  //padding-left: 3px;
  //padding-right: 0px;
  border: 0;
  width: auto;
  border-radius: 50%;
  height: auto;
  backdrop-filter: invert(1);
  cursor: pointer;
}


.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 15px;
  bottom: 15px;
  min-width: 490px;
  padding: 23px;
  z-index: 7;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  background: linear-gradient(45deg, var(--white), var(--light_blue_40));

  @media (max-width: 767px) {
    width: 80vw;
    min-width: unset;
    padding: 10px;
  }
}

.btn-modal--close {
  top: 10px;
  background-color: var(--white);
  align-self: flex-end;
  outline: none;
  cursor: pointer;
  margin-bottom: 10px;
  position: absolute;
  color: var(--white);
  i {
    color: var(--white);
  }
}

.modal-btn {
  cursor: pointer;
  background-color: inherit;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
  i {
    color: var(--color-primary);
    &:before {
      font-size: 1.5em;
    }
  }
}

.modal-svg {
  margin-left: 16px;
  color: rgb(255,255,255, 0.9);
  vertical-align: center;
}

.btn-callNow {
  font-weight: bold;
}

.modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.form-top {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 44px;
}

.form-title {
  color: var(--color-primary);
  font-size: 19px;
  font-weight: normal;
  margin-bottom: 24px;
}
.form-mid {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.modal-input {
  width: 75%;
  background-color: inherit;
  border-bottom: 1px solid var(--color-primary);
  height: 50px;
  color: var(--gray_900);
  font-size: 16px;
  font-weight: lighter;
  padding: 10px;
  background: var(--light_blue_40);
  &:focus-visible{
    //outline: 1px solid var(--color-primary;
    outline: none;
  }
}

.modal-input::placeholder {
  color: var(--color-primary);
  font-size: 21px;
  font-weight: lighter;
  padding: 10px;
}

.btn-callMe {
  min-width: fit-content;
  width: fit-content;
  padding: 16px;
}

.btn-message {
  height: fit-content;
  width: fit-content;
  min-width: fit-content;
  background: var(--white);
}

.modal-agreement {
  font-size: 12px;
  font-weight: normal;
  color: var(--color-primary);
}

.input-optionalInfo {
  visibility: hidden;
  transition: visibility 0.2s linear;
}

.waiting-block {
  display: none;
  flex-direction: column;
  margin-bottom: 25px;
}

.waiting-block-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
}

.waiting-block-wrapper .btn-callMe {
  opacity: 1;
  margin: 0;
}

.waiting-block-title {
  color: var(--color-primary);
  font-size: 26px;
  margin: 0 0 65px 0;
}

.waiting-block-title-phone {
  color: var(--color-primary);
  font-size: 26px;
}

@media (max-width: 767px) {
  .form-top {
    flex-wrap: wrap;
  }

  .btn-callMe {
    align-self: center;
  }

  .modal-agreement {
    font-size: 12px;
  }

  .form-mid {
    margin-bottom: 25px;
  }

  .waiting-block-wrapper {
    flex-flow: row wrap-reverse;
  }

  .waiting-block-wrapper .modal-btn {
    width: 90%;
    text-align: center;
  }
  .input-optionalInfo::placeholder {
    font-size: 15px;
  }

  .modal-input {
    width: 100%;
  }
}

