.labeler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
  min-height: 70vh;
  width: 100%;

  .left-column-labeler {
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    height: 70%
  }
  .right-column {
    max-width: 30%;
    overflow: hidden;
    //height: 100%;
    .right-column-content {
      max-height: 70vh;
      overflow-y: scroll;
    }
  }
  
}

@import "labeler-xs";