body, html {
  padding: 0;
  // background: linear-gradient(249.73deg, #A3CAE2 -2.12%, #FFFFFF 73.16%);
  background: linear-gradient(249.73deg, var(--black_blue24) -2.12%, var(--black_blue10) 73.16%);
  color: var(--gray-20);
  min-height: 100vh;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--light_blue_100); /* or add it to the track */
}

/* Add a thumb */
*::-webkit-scrollbar-thumb {
  background: #5b84a1;
}

@import "body-sm";
@import "body-md";
@import "body-lg";
@import "body-xl";
@import "body-xs";

