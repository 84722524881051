.callback {
  position: fixed;
  z-index: 4;
  bottom: 11%;
  right: 10%;
  width: auto;
  border-radius: 50%;
  height: auto;
  backdrop-filter: invert(1);

  button {
    background: var(--color-primary);
    color: var(--light_blue_40);
  }

  a {
    color: var(--color-primary);
    background: var(--light_blue_40);
    box-shadow: 0px 0px 10px 0px rgba(31, 99, 163, 0.15);
    height: 30px;
    padding: 6px;
    border-radius: 5px;
  }
}
