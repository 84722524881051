.learning-model-widget {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  background: var(--white);
  padding: 7px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  .id {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 7px;
    color: var(--light_blue_400);
  }

  p.title {
    //styleName: Title/Medium;
    padding: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0px;
    width: 100%;
    color: var(--blue-primary);
  }

  .epochs {
    margin: 0;
    font-size: 12px;
    font-weight: 900;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    color: var(--green_350);
  }

  .status {
    margin: 0;
    font-size: 12px;
    font-weight: 900;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 5px;
    color: var(--gray_700);
  }

  p.description {
    font-size: 10px;
    color: var(--gray_900);
  }
}