.detections-index {
  display: flex;
  flex-direction: column;
  overflow: visible;

  .detections-list {
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      max-height: 40vh;
      overflow-y: visible;
      overflow-x: visible;
      height: 100%;
      margin-bottom: 10px;
  }
}