.new-question {
  display: flex;
  flex-direction: column;
  color: var(--black);
  min-width: 50vw;
  textarea {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}