.article {
  height: 100%;
  background: var(--white);
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 8px;
  color: var(--violet_dark);

  max-width: 70vw;
  max-height: 80vh;
  overflow-y: auto;
  h1 {
    font-size: 18px;
  }
}