.labeler {
  overflow-y: scroll;
  * {
    box-sizing: border-box;
  }
  

  .photo-upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 300px;
    margin-right: auto;
    margin-left: 0;
  }

  .columns-container {
    display: flex;
    justify-content: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
  }

  .left-column {
    padding-right: 10px;
    height: 70%;
  }

  .right-column {
    box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);
    background: var(--white);
    background: linear-gradient(135deg, #0b96f90d 100%, rgba(255,255,255,0.1) 100%);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
    h5 {
      font-size: 15px;
      margin: 0;
      margin-bottom: 20px;
      color: var(--white);
      opacity: 0.8;
      font-weight: 300;
    }

    button {
      font-size: 12px;
      max-width: 220px;
    }

    .tab-activations-zone {
      display: flex;
      flex-direction: row;
      button {
        border-radius: 5px;
        margin-right: 5px;
      }
    }
  }

  form {
    margin-top: 6px;
    margin-bottom: 22px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: 220px;
    max-width: 220px;
    overflow: hidden;
    border-radius: 10px;
    input {
      font-size: 12px;
      background: transparent;
      border: none;
      border: 2px solid var(--color-primary);
      padding: 5px; 
      border-radius: 10px;
      color: var(--white);

      &:focus {
        outline: none;
      }
    }

    .hint {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      justify-content: flex-start;
      overflow-x: scroll;
      padding-bottom: 4px;

      &::-webkit-scrollbar {
        height: 2px;
      }

      &::-webkit-scrollbar-track {
        background: var(--blue-primary);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--white);
        border-radius: 2px;
      }

      .button {
        cursor: pointer;
        padding: 5px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 3px;
        color: var(--white);
        font-size: 10px;
        display: flex;
        border-radius: 10px;
        border: 1px solid var(--color-primary);
        text-overflow: ellipsis;
        white-space: nowrap;
        &:hover {
          color: var(--light_blue_40);
          background: var(--color-primary);
        }
      }
    }
  }

  form input {
    font-size: 14px;
  }

  form input:last-of-type {
    width: 100%;;
  }

  form button {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
  }

  button,
  input[type=submit] {
    text-transform: uppercase;
    padding: 8px 15px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  button:hover {
    background-color: #a71a21;
  }

  /* button,
  input[type=reset] {
    text-transform: uppercase;
    padding: 8px 15px;
    border: 0;
    background: #f00;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  } */

  .label-cards-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 780px;
  }

  .labelCard {
    background: white;
    box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin: 0px;
    margin-bottom: 10px;
    border: 0;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
    max-width: 220px;
    transition: 300ms;
    filter: brightness(0.9);
    &:hover {
      box-shadow: 4px 0px 7px 0px rgba(0, 0, 0, 0.05);
      filter: brightness(1.1);
    }

    

    p.time {
      margin: 0;
      margin-left: auto;
      font-size: 10px;
      i {
        margin-right: 10px;
      }
    }
    //padding-right: 18px;
  }

  .labelCard h2 {
    background: white;
    display: inline;
    margin: 0;
    font-size: 14px;
    color: var(--gray_900);
    margin-right: auto;
    line-height: 1.01em;
    text-align: left;
  }

  button { background-color: var(--color-primary)}
  .labelCard button {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: var(--color-primary);
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    margin: 2px 0px 2px 5px;
  }

  .labelCard button:last-child {
    margin-right: 0;
  }

  .labelCard button:hover {
    background-color: #0056b3;
  }

  .edit-label-form {
    border: 1px solid #ccc;
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 12px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }

  .edit-label-form label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }



  .edit-label-form button {
    margin-right: 4px;
    width: 45%;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }


}
