.projects {
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 14px;

  @media (max-width: 1000px) {
    padding: 0;
  }

  h3 {
    margin: 0;
  }
}