.video-detections-preview {
  position: relative;
  min-width: 70vw;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .video-container {
    position: relative;
    display: flex;
    width: fit-content;
    height: fit-content;

    .detection {
      position: absolute;
      z-index: 4;
      border: 2px solid var(--green_nice);
      .title {
        background: var(--green_nice);
        color: var(--white);
        font-size: 8px;
        position: absolute;
        top: -8px;
        left: 0;
        padding: 3px;
      }
    }

    .activation-zone {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background: var(--white_alpha16);
      //background: #ff253a;
      .activation-column {
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;

        .level-of-detections {
          display: flex;
          width: 100%;
          background: var(--green_nice);
          opacity: 0.4;
          margin-top: auto;
          height: 40px;
        }
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }
}