$controlling_number: 10px;

.spacer { display: flex;}

$value: 0;
@for $i from 0 through 15 {
  //--font_size_#{$i}: #{$value * 1px};
  .v-spacer-#{$value} { @extend .spacer; height: calc($value * $controlling-number)}
  .h-spacer-#{$value} { @extend .spacer; width: calc($value * $controlling-number)px}
  $value: $value + 1;
}

@import "spacers-xs";
@import "spacers-sm";
@import "spacers-md";
@import "spacers-lg";
@import "spacers-xl";
