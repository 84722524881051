.stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .timeline {
        display: flex;
        flex-direction: row;
        gap: 10px;
        overflow: auto;

        .timeline__item {
            flex: 1;
            cursor: pointer;
            text-align: center;
            font-size: 9px;
        }
    }
    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #fff;
        background-color: #0f0;
        max-height: 3px;
        width: 5%;
        text-align: right;
        border-radius: 5px;
        transition: width 0.3s ease-in-out;
        &-email {
            font-size: 8px;
            white-space: nowrap;
            width: 100%;
            color: #000;
            text-align: left;
        }
        &-counter {
            font-size: 10px;
            width: 100%;
            color: #000;
            font-weight: bold;
        }
    }
}