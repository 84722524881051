.data-source-index {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
  padding-bottom: 20vh;

  .data-source-widgets-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
    width: 100%;
    .data-source-file-widget {
      width: calc(25%);
      margin: 0px;
      position: relative;

      @media (max-width: 1500px) {
        width: calc(33.3%);
      }

      @media (max-width: 1100px) {
        width: calc(100%);
      }

      //&:nth-last-child(3) {
      //  width: 33%;
      //}
      //&:nth-last-child(2) {
      //  width: 33%;
      //}
      &:nth-last-child(1) {
        margin-right: auto;
      }

      &:hover {
      }
      //margin-bottom: 6px;
    }

    .data-source-file-widget:last-child {
      margin-right: auto;
    }
  }
}