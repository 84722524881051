.project-widget {
  background: var(--white);
  padding: 17px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 17px;
  cursor: pointer;

  p {
    margin-top: 10px;
  }
  
  p.title {
    margin: 0;
    color: var(--blue-primary);
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
}
