.circle-loader {
  /*margin-bottom: 3.5em;*/
  /*border: 1px solid rgba(119, 175, 244, 0.3);*/
  /*border-left-color: #ffffff;*/
  animation: loader-spin 2.2s infinite linear;
  /*position: relative;*/
  display: inline-block;
  /*vertical-align: top;*/
  /*border-radius: 50%;*/
  /*width: 7em;*/
  /*height: 7em;*/
}

@keyframes loader-spin {
  0 % {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
