.data-source-file-widget {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  filter: brightness(0.8);
  &:hover {
    filter: brightness(1);
  }
  border: none;
  padding: 0px;
  //border-radius: 10px;
  border-radius: 0;
  height: 300px;

  background: var(--white);
  position: relative;

  p.title {
    margin: 10px;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0px;
    position: relative;
    z-index: 1;
  }

  p.description {
    margin: 4px;
    margin-left: 10px;
    padding: 0;
    color: var(--white);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    z-index: 1;
  }

  p.ai-description {
    opacity: 1;
    z-index: 120;
    font-size: 14px;
    margin: 5px;
    margin-left: 10px;
    width: calc(100% - 20px);
    padding: 5px;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(120deg, rgba(64, 224, 208, 0.15), rgba(140, 0, 255, 0.15));
    border: 1px solid rgba(140, 0, 255, 0.2);
    box-shadow: 0 4px 15px rgba(64, 224, 208, 0.1);
    backdrop-filter: blur(5px);
  }

  &:hover {
    p.ai-description {
    }
  }

  button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    border: 2px solid var(--gray_300);
    color: var(--gray_300);
    opacity: 0.8;
    //border-radius: 10px 0px 10px 0;
    backdrop-filter: blur(2px);
  }

  video, img {
    display: flex;
    //height: 90px;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px;
    border: none;
    z-index: 0;
    filter: brightness(0.6);
  }
}