.detection-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
  background: var(--white);
  padding: 4px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  h4, p {
    margin: 0;
  }

  .detections {
    font-size: 10px;
  }

}