.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
@for $i from 0 through 145 {
  .ml-#{$i} { margin-left: calc($i * 1px); }
  .mr-#{$i} { margin-right: calc($i * 1px); }
  .mt-#{$i} { margin-top: calc($i * 1px); }
  .mt-#{$i}vh { margin-top: calc($i * 1vh); }
  .mb-#{$i} { margin-bottom: calc($i * 1px); }
  .mb-#{$i}vh { margin-bottom: calc($i * 1vh); }
  .m-#{$i} { margin: calc($i * 1px); }
  .pl-#{$i} { padding-left: calc($i * 1px); }
  .pr-#{$i} { padding-right: calc($i * 1px); }
  .pt-#{$i} { padding-top: calc($i * 1px); }
  .pb-#{$i} { padding-bottom: calc($i * 1px); }
  .p-#{$i} { padding: calc($i * 1px); }
}