.modal-container {
  position: fixed;
  z-index: 6;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: var(--black_alpha56);

    .content {
      background: var(--gray-15);
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }
}